<template>
  <el-container>
    <el-header class="home_header">
      <div class="home_header_logo">
        <el-image
          style="width: 80px; height: 100%"
          :src="require('@/assets/images/logo/header.png')"
          :fit="contain"
        ></el-image>
      </div>
      <div class="home_header_logout">
        <el-button plain @click="loginOut">退出登录</el-button>
      </div>
    </el-header>
    <el-container
      ><el-aside width="200px" class="home_aside">
        <el-menu :default-active="$route.path" router class="home_aside_menu">
          <el-menu-item index="/homePage">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="/userManage">
            <i class="el-icon-user"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="iconfont icon-molecule"></i>
              <span>分子条目</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/moleculeInfoManage">
                <i class="iconfont icon-molecule"></i>
                <span slot="title">分子条目管理</span>
              </el-menu-item>
              <el-menu-item index="/moleculeInfoAuditRecordSelect">
                <i class="iconfont icon-molecule"></i>
                <span slot="title">分子条目审核记录</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <i class="iconfont icon-molecule"></i>
              <span>分子性质</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/propertieRecordNotAuditRecordManage"
                ><i class="el-icon-edit"></i>
                <span slot="title">分子性质审核</span></el-menu-item
              >
              <el-menu-item index="/propertieRecordAlreadyAuditRecordManage"
                ><i class="el-icon-tickets"></i>
                <span slot="title">审核历史记录</span></el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/articleManage">
            <i class="el-icon-document"></i>
            <span slot="title">文章管理</span>
          </el-menu-item>
          <el-menu-item index="/networkModelManage">
            <i class="iconfont icon-moxing"></i>
            <span slot="title">网络模型管理</span>
          </el-menu-item>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-tickets"></i>
              <span>日志管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/logLogin"
                ><i class="el-icon-document"></i>
                <span slot="title">登录日志</span></el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu></el-aside
      >
      <el-container>
        <el-main class="main_box"><router-view /></el-main>
      </el-container>
    </el-container>
    <el-footer class="home_footer">
      <div class="home_footer_copyright">
        <span>Powered by Tongren University</span>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  methods: {
    loginOut() {
      this.$store.dispatch("user/loginOut").then((res) => {
        if (res) {
          {
            this.$message.warning("退出登录成功！");
            this.$router.push("/login");
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.home_header {
  height: 10vh !important;
  background-color: #304156;
}
.home_header_logo {
  margin-top: 20px;
  margin-left: 20px;
  float: left;
  color: #fff;
}

.home_header_logo span {
  margin-left: 20px;
}

.home_header_logout {
  margin-top: 2.5vh;
  float: right;
  width: 5rem;
}

.home_aside {
  height: 85vh;
  box-shadow: 10px 6px 10px 1px rgb(167, 167, 167);
}

.home_aside_menu {
  height: 85vh;
}

::v-deep .el-menu {
  border: 0px;
}
.main_box {
  height: 85vh;
  overflow-y: overlay;
  overflow-x: hidden;
}
.home_footer {
  display: flex;
  height: 5vh !important;
  background-color: #304156;
  color: #fff;
}

.home_footer_copyright {
  margin: auto;
}
</style>
